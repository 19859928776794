import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "src/scss/main.css";
import { Web3Provider } from "@ethersproject/providers";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { NetworkContextName } from "src/constants";


const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);
function getLibrary(provider) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 15000;
    return library;
}
ReactDOM.render(

    <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
            <App />
        </Web3ProviderNetwork>
    </Web3ReactProvider>

    , document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
